<template>
	<r-panel-container :title="`${tipoFormulario} Base de datos`"
	                   icon="far fa-laptop-binary"
	                   :breadcrumb="breadcrumb">
		<template #toolbar>
			<button @click.prevent="guardar"
			        class="btn btn-dark"
			        :disabled="deshabilitarFormulario">
				<template v-if="!guardando">
					<i class="fa fa-save"></i>
					Guardar
				</template>
				<template v-else>
					<i class="fa fa-sync fa-spin"></i>
					Guardando
				</template>
			</button>
		</template>
		<template #body>
			<div class="row">
				<div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 pt-3">
					<button class="btn btn-dark w-100 mb-3"
					        :disabled="cargando || guardando || guardandoDisciplina"
					        @click.prevent="abrirModalMultimedia">
						<i class="fa fa-image"></i>
						Seleccionar imagen
					</button>
					<small class="text-danger" v-html="formularioBaseDatos.errores.ruta_imagen"/>
					<img v-if="formularioBaseDatos.info.ruta_imagen && errorCargaImagen === false"
					     :src="formularioBaseDatos.info.ruta_imagen"
					     @load="errorCargaImagen = false"
					     @error="errorCargaImagen = true"
					     class="w-100 img-thumbnail shadow-sm mx-auto mb-4"
					     alt=""/>
					<div v-if="errorCargaImagen"
					     class="alert alert-danger">
						<i class="far fa-image me-2"/>
						La imagen no esta disponible
					</div>
				</div>
				<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 pt-3">
					<div class="mb-4">
						<div class="d-flex">
							<div class="w-100 me-2 position-relative">
								<r-multiple-select v-model="formularioBaseDatos.info.disciplinas"
								                   :options="disciplinas"
								                   style-fill
								                   key-name="nombre"
								                   value-name="id"
								                   :disabled="cargando || guardando || guardandoDisciplina"
								                   :placeholder="`Disciplinas ${cargando ? '<i class=\'ms-2 fa fa-spinner-third fa-spin fa-spin-fast\'></i> ' : ''}`"/>
							</div>
							<div>
								<button class="btn btn-primary"
								        :disabled="cargando || guardando || guardandoDisciplina"
								        @click.prevent="abrirModalNuevaDisciplina">
									<i class="fa fa-plus"></i>
								</button>
							</div>
						</div>
						<small class="text-danger" v-html="formularioBaseDatos.errores.disciplinas"/>
					</div>
					<div class="mb-4">
						<r-input v-model="formularioBaseDatos.info.nombre"
						         style-fill
						         :disabled="cargando || guardando || guardandoDisciplina"
						         placeholder="Nombre"/>
						<small class="text-danger" v-html="formularioBaseDatos.errores.nombre"/>
					</div>
					<div class="mb-4">
						<r-input v-model="formularioBaseDatos.info.enlace"
						         style-fill
						         :disabled="cargando || guardando || guardandoDisciplina"
						         placeholder="Enlace web"/>
						<small class="text-danger" v-html="formularioBaseDatos.errores.enlace"/>
					</div>
					<div class="mb-4">
						<r-input v-model="formularioBaseDatos.info.descripcion"
						         style-fill
						         type="textarea"
						         :rows="10"
						         :disabled="cargando || guardando || guardandoDisciplina"
						         placeholder="Descripcion"/>
						<small class="text-danger" v-html="formularioBaseDatos.errores.descripcion"/>
					</div>

				</div>
			</div>
		</template>
	</r-panel-container>
	<selector-multimedia ref="selectorMultimedia"
	                     v-model="formularioBaseDatos.info.ruta_imagen"
	                     @change="errorCargaImagen = false"
	                     :file-types="['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp', 'webp']"/>

	<r-modal ref="modalNuevaDisciplina" @on-close="cerrarModalNuevaDisciplina">
		<template #title>Añadir disciplina</template>
		<template #body>
			<div class="p-3">
				<r-input type="text"
				         style-fill
				         placeholder="Nombre"
				         v-model="formularioDisciplinas.info.nombre"
				         :disabled="guardandoDisciplina"
				         @keyup.enter.stop="guardarNuevaDisciplina"/>
				<small class="text-danger" v-html="formularioDisciplinas.errores.nombre"/>
			</div>
		</template>
		<template #footer="{modal}">
			<div class="w-100 d-flex justify-content-between">
				<button @click.prevent="modal.close"
				        :disabled="guardandoDisciplina"
				        class="btn btn-danger">
					<i class="fa fa-times"></i>&nbsp;Cancelar
				</button>
				<button @click.prevent="guardarNuevaDisciplina"
				        :disabled="guardandoDisciplina"
				        class="btn btn-success">
					<i class="fa fa-check"></i>
					Aceptar
				</button>
			</div>
		</template>
	</r-modal>
</template>
<script setup lang="ts">
import {RInput, RModal, RMultipleSelect, RPanelContainer} from "base-app/dist/components";
import {useNotify} from "base-app/dist/composable";
import {clearErrors, equals, showErrors} from "base-app/dist/utils";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {IFormulario} from "../../interfaces/IFormulario.ts";
import {IBaseDatos} from "../../interfaces/IBaseDatos.ts";
import {BasesDatos} from "../../api/BasesDatos.ts";
import {Disciplinas} from "../../api/Disciplinas.ts";
import {IDisciplina} from "../../interfaces/IDisciplina.ts";
import SelectorMultimedia from "../../components/SelectorMultimedia.vue";

const breadcrumb = computed(() => ([
	{title: 'Bases de datos', name: 'bases.datos'},
	{title: tipoFormulario.value + ' Base de datos'},
]));

const notify = useNotify();
const router = useRouter();
const route = useRoute();

const modalNuevaDisciplina = ref<typeof RModal>();
const selectorMultimedia = ref<typeof SelectorMultimedia>();
const cargando = ref<boolean>(false);
const guardando = ref<boolean>(false);
const guardandoDisciplina = ref<boolean>(false);
const errorCargaImagen = ref<boolean>(false);

const disciplinas = ref<IDisciplina[]>([]);

const formularioDisciplinas = ref<IFormulario<IDisciplina>>({
	errores: {
		nombre: []
	},
	info: {
		nombre: '',
	}
});

const ultimosDatosFormulario = ref<IBaseDatos>({
	nombre: '',
	ruta_imagen: '',
	enlace: '',
	descripcion: '',
	disciplinas: [],
});
const formularioBaseDatos = ref<IFormulario<IBaseDatos>>({
	info: {
		nombre: '',
		ruta_imagen: '',
		enlace: '',
		descripcion: '',
		disciplinas: [],
	},
	errores: {
		nombre: [],
		ruta_imagen: [],
		enlace: [],
		descripcion: [],
		disciplinas: [],
	}
});

onMounted(async () => {
	await cargarInformacionFormulario();
	await cargarBaseDatos();
});

const abrirModalMultimedia = () => {
	selectorMultimedia.value?.open();
};

const abrirModalNuevaDisciplina = () => {
	modalNuevaDisciplina.value?.open();
};
const cerrarModalNuevaDisciplina = () => {
	formularioDisciplinas.value.info.nombre = '';
};

const guardarNuevaDisciplina = async () => {
	guardandoDisciplina.value = true;
	clearErrors(formularioDisciplinas.value.errores);
	try {
		const {data} = await Disciplinas.nuevo({
			nombre: formularioDisciplinas.value.info.nombre
		});
		if (data.estado) {
			const info = {
				id: data.info.id,
				nombre: data.info.nombre,
			};
			disciplinas.value.push(info);
			formularioBaseDatos.value.info.disciplinas.push(info.id as string);
			notify.success('Disciplina creada correctamente.');
			cerrarModalNuevaDisciplina();
			modalNuevaDisciplina.value?.close();
		} else {
			showErrors(data.mensajes, formularioDisciplinas.value.errores);
		}
	} catch {
		notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
	} finally {
		guardandoDisciplina.value = false;
	}
};
const cargarInformacionFormulario = async () => {
	cargando.value = true;
	try {
		const {data} = await Disciplinas.lista();
		disciplinas.value = data;
	} catch {
		notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
		volverAtras();
	} finally {
		cargando.value = false;
	}
};
const cargarBaseDatos = async () => {
	if (route.params.id) {
		cargando.value = true;
		try {
			const {data} = await BasesDatos.porId(route.params.id as string);
			if (data.estado) {
				formularioBaseDatos.value.info.nombre = data.info.nombre;
				formularioBaseDatos.value.info.ruta_imagen = data.info.ruta_imagen;
				formularioBaseDatos.value.info.enlace = data.info.enlace;
				formularioBaseDatos.value.info.descripcion = data.info.descripcion;
				formularioBaseDatos.value.info.disciplinas = data.info.disciplinas;
				ultimosDatosFormulario.value = JSON.parse(JSON.stringify(formularioBaseDatos.value.info));
			} else {
				notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
				volverAtras();
			}
		} catch {
			notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
			volverAtras();
		} finally {
			cargando.value = false;
		}
	}
};

const guardar = async () => {
	guardando.value = true;
	clearErrors(formularioBaseDatos.value.errores);
	try {
		let data;
		if (route.params.id) {
			formularioBaseDatos.value.info.id = route.params.id as string;
			const resultado = await BasesDatos.actualizar(formularioBaseDatos.value.info);
			data = resultado.data;
		} else {
			const resultado = await BasesDatos.nuevo(formularioBaseDatos.value.info);
			data = resultado.data;
		}
		if (data.estado) {
			notify.success('Datos guardados con exito');
			volverAtras();
		} else {
			showErrors(data.mensajes, formularioBaseDatos.value.errores);
		}
	} catch {
		notify.error('Error al intentar guardar');
		volverAtras();
	} finally {
		guardando.value = false;
	}
}
const volverAtras = () => {
	router.push({
		name: 'bases.datos',
	});
};

const deshabilitarFormulario = computed(() => {
	return guardandoDisciplina.value === true || cargando.value === true || guardando.value === true || equals(ultimosDatosFormulario.value, formularioBaseDatos.value.info);
});

const tipoFormulario = computed(() => {
	return route.params.id ? 'Editar' : 'Nueva';
});
</script>