import {RouteRecordRaw} from "vue-router";
import Lista from "../pages/bases-datos/BasesDatos.vue";
import FormularioBasesDatos from "../pages/bases-datos/FormularioBasesDatos.vue";

export const BasesDatos: RouteRecordRaw[] = [
    {
        name: 'bases.datos',
        path: '/bases-datos',
        component: Lista,
        meta: {title: 'Bases de datos'}
    },
    {
        name: 'bases.datos.nuevo',
        path: '/bases-datos/nuevo',
        component: FormularioBasesDatos,
        meta: {title: 'Nueva bases de datos'}
    },
    {
        name: 'bases.datos.editar',
        path: '/bases-datos/:id',
        component: FormularioBasesDatos,
        meta: {title: 'Editar bases de datos'}
    },
];