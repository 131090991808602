import {RouteRecordRaw} from "vue-router";
import Error404 from "../pages/errores/Error404.vue";

export const Errores: RouteRecordRaw[] = [
    {
        name: 'error.not.found',
        path: '/:catchAll(.*)*',
        component: Error404,
        meta: {
            title: 'No encontrado',
        }
    }
];