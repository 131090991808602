<template>
	<r-notifications/>
	<r-downloads/>
	<r-dialogs/>
	<template v-if="usuario">
		<r-dashboard :user="usuario"
		             :user-menu="menu.userMenu"
		             :main-menu="menu.mainMenu">
			<template #append>
				<div class="d-flex justify-content-end">
					<r-dropdown-theme class="dropdown-text-white"/>
				</div>
			</template>
			<template #title>
				<span class="text-white">Open Access</span>
			</template>
			<template #content>
				<router-view/>
			</template>
		</r-dashboard>
	</template>
	<router-view v-else/>
</template>
<script setup lang="ts">
import {computed} from "vue";
import {useSession} from 'base-app/dist/composable';
import {RDialogs, RDownloads, RDropdownTheme, RNotifications} from "base-app/dist/components";
import {RDashboard} from "base-app/dist/layout";
import {useMenu} from "./commons/Menu.ts";
import {IUsuario} from "./interfaces/IUsuario.ts";
import {ISidebarData} from "base-app/dist/interfaces";

const menu = useMenu();
const session = useSession();

const usuario = computed(() => {
	const usuarioSesion = session.get<IUsuario>('usuario');
	if (usuarioSesion) {
		return {
			name: [usuarioSesion.nombres, usuarioSesion.apellidos].join(' '),
			email: usuarioSesion.email,
			photo: usuarioSesion.foto || '',
		} as ISidebarData;
	}
	return undefined;
});
</script>