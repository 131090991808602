import {createRouter, createWebHistory, START_LOCATION} from "vue-router";
import {nextTick} from "vue";
import {useSession} from "base-app/dist/composable";
import {Errores} from "./errores.ts";
import {Usuario} from "../api/Usuario.ts";
import {BasesDatos} from "./bases-datos.ts";
import {Multimedia} from "./multimedia.ts";
import {Admin} from "./admin.ts";
import {Libros} from "./libros.ts";
import {Tokens} from "./tokens.ts";
import {Disciplinas} from "./disciplinas.ts";
import {Temas} from "./temas.ts";
import {Autores} from "./autores.ts";
import {trim} from "base-app/dist/utils";

const session = useSession();


const Router = createRouter({
    history: createWebHistory(),
    routes: [
        ...Admin,
        ...Multimedia,
        ...BasesDatos,
        ...Disciplinas,
        ...Libros,
        ...Temas,
        ...Autores,
        ...Tokens,
        ...Errores,
    ],
});

Router.beforeEach(async (to, from, next) => {
    if (from === START_LOCATION && to.name !== 'inicio.sesion') {
        try {
            const {usuario} = await Usuario.verificar_token();
            session.set('usuario', usuario);
            if (!usuario) {
                let newTo = '';
                const path = trim(to.fullPath, '/');
                if (path) {
                    newTo = '?to=' + path
                }
                window.location.href = `/inicio-sesion${newTo}`;
                return;
            }
        } catch {
        }
    }
    window.scrollTo({top: 0, behavior: 'instant'});
    next();
});

Router.afterEach(async (to) => {
    await nextTick();
    let temp = import.meta.env.VITE_APP_WINDOW_TITLE;
    if (to.meta && to.meta.title) {
        temp = `${temp} - ${to.meta.title}`;
    }
    document.title = temp;
});

export default Router;