import './preloader.ts';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'base-app/dist/assets/font-awesome.css';
import 'base-app/dist/assets/style.css';
import './assets/scss/_styles.scss';

import {createApp} from 'vue';
import Router from "./routes";
import {BaseApp} from 'base-app/dist';
import App from './App.vue';

createApp(App)
    .use(BaseApp, {
        favicon: {
            href: 'https://cabid.cl/wp-content/uploads/2021/03/cabid-s.png',
        },
    })
    .use(Router)
    .mount('#app');