<template>
	<r-panel-container :title="`${tipoFormulario} libro`"
	                   icon="fal fa-books"
	                   :breadcrumb="breadcrumb">
		<template #toolbar>
			<button @click.prevent="guardar"
			        class="btn btn-dark btn-sm"
			        :disabled="deshabilitarFormulario">
				<template v-if="!guardando">
					<i class="fa fa-save"></i>
					Guardar
				</template>
				<template v-else>
					<i class="fa fa-sync fa-spin"></i>
					Guardando
				</template>
			</button>
		</template>
		<template #body>
			<div class="row">
				<div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
					<button class="btn btn-dark w-100 mb-3"
					        :disabled="cargando || guardandoAutorTema || guardando"
					        @click.prevent="abrirModalMultimedia">
						<i class="fa fa-image"></i>
						Seleccionar imagen
					</button>
					<small class="text-danger" v-html="formularioLibro.errores.ruta_imagen"/>
					<img v-if="formularioLibro.info.ruta_imagen && errorCargaImagen === false"
					     :src="formularioLibro.info.ruta_imagen"
					     @load="errorCargaImagen = false"
					     @error="errorCargaImagen = true"
					     class="w-100 img-thumbnail shadow-sm mx-auto mb-4"
					     alt=""/>
					<div v-if="errorCargaImagen"
					     class="alert alert-danger">
						<i class="far fa-image me-2"/>
						La imagen no esta disponible
					</div>
				</div>
				<div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
					<div class="row">
						<div class="col-12">
							<div class="mb-4">
								<r-input v-model="formularioLibro.info.titulo"
								         :disabled="cargando || guardandoAutorTema || guardando"
								         style-fill
								         placeholder="Titulo"/>
								<small class="text-danger" v-html="formularioLibro.errores.titulo"/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
							<div class="mb-4">
								<r-input v-model="formularioLibro.info.isbn"
								         :disabled="cargando || guardandoAutorTema || guardando"
								         style-fill
								         v-only-nums
								         v-max-length="13"
								         placeholder="ISBN"/>
								<small class="text-danger" v-html="formularioLibro.errores.isbn"/>
							</div>
						</div>
						<div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
							<div class="mb-4">
								<r-input v-model.number="formularioLibro.info.ano"
								         :disabled="cargando || guardandoAutorTema || guardando"
								         style-fill
								         v-only-nums
								         v-max-length="4"
								         placeholder="Año"/>
								<small class="text-danger" v-html="formularioLibro.errores.ano"/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="mb-4">
								<r-input v-model="formularioLibro.info.url"
								         :disabled="cargando || guardandoAutorTema || guardando"
								         style-fill
								         placeholder="Enlace"/>
								<small class="text-danger" v-html="formularioLibro.errores.url"/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="mb-4">
								<r-input v-model="formularioLibro.info.editorial"
								         :disabled="cargando || guardandoAutorTema || guardando"
								         style-fill
								         placeholder="Editorial"/>
								<small class="text-danger" v-html="formularioLibro.errores.editorial"/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
							<div class="mb-4">
								<r-input v-model="formularioLibro.info.lugar"
								         :disabled="cargando || guardandoAutorTema || guardando"
								         style-fill
								         placeholder="Lugar"/>
								<small class="text-danger" v-html="formularioLibro.errores.lugar"/>
							</div>
						</div>
						<div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
							<div class="mb-4">
								<r-input v-model="formularioLibro.info.dificultad_acceso"
								         :disabled="cargando || guardandoAutorTema || guardando"
								         style-fill
								         placeholder="Dificultad de acceso"/>
								<small class="text-danger" v-html="formularioLibro.errores.dificultad_acceso"/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="mb-4">
								<div class="d-flex">
									<div class="w-100 me-2 position-relative">
										<r-multiple-select v-model="formularioLibro.info.autores"
										                   :disabled="cargando || guardandoAutorTema || guardando"
										                   :options="autores"
										                   style-fill
										                   key-name="nombre"
										                   value-name="id"
										                   :placeholder="`Autores ${cargando ? '<i class=\'fal fa-spinner-third fa-spin fa-spin-fast ms-2\'></i>' : ''}`"/>
									</div>
									<div>
										<button class="btn btn-primary"
										        :disabled="cargando || guardandoAutorTema || guardando"
										        @click.prevent="abrirModalNuevoAutor">
											<i class="fa fa-plus"></i>
										</button>
									</div>
								</div>
								<small class="text-danger" v-html="formularioLibro.errores.autores"/>
							</div>
							<div class="mb-4">
								<div class="d-flex">
									<div class="w-100 me-2 position-relative">
										<r-multiple-select v-model="formularioLibro.info.temas"
										                   :disabled="cargando || guardandoAutorTema || guardando"
										                   :options="temas"
										                   style-fill
										                   key-name="nombre"
										                   value-name="id"
										                   :placeholder="`Temas ${cargando ? '<i class=\'fal fa-spinner-third fa-spin fa-spin-fast ms-2\'></i>' : ''}`"/>
									</div>
									<div>
										<button class="btn btn-primary"
										        :disabled="cargando || guardandoAutorTema || guardando"
										        @click.prevent="abrirModalNuevoTema">
											<i class="fa fa-plus"></i>
										</button>
									</div>
								</div>
								<small class="text-danger" v-html="formularioLibro.errores.temas"/>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
					<div class="mb-4">
						<r-input v-model="formularioLibro.info.resumen"
						         :disabled="cargando || guardandoAutorTema || guardando"
						         style-fill
						         type="textarea"
						         rows="10"
						         placeholder="Resumen"/>
						<small class="text-danger" v-html="formularioLibro.errores.resumen"/>
					</div>
				</div>
			</div>
		</template>
	</r-panel-container>

	<selector-multimedia ref="selectorMultimedia"
	                     v-model="formularioLibro.info.ruta_imagen"
	                     @change="errorCargaImagen = false"
	                     :file-types="['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp', 'webp']"/>

	<r-modal ref="modalNuevoAutor" @on-close="cerrarModalNuevoAutor">
		<template #title>Añadir autor</template>
		<template #body>
			<div class="p-3">
				<r-input type="text"
				         style-fill
				         placeholder="Nombre"
				         v-model="formularioAutor.info.nombre"
				         :disabled="guardandoAutorTema"
				         @keyup.enter.stop="guardarNuevoAutor"/>
				<small class="text-danger" v-html="formularioAutor.errores.nombre"/>
			</div>
		</template>
		<template #footer="{modal}">
			<div class="w-100 d-flex justify-content-between">
				<button @click.prevent="modal.close"
				        class="btn btn-danger">
					<i class="fa fa-times"></i>&nbsp;Cancelar
				</button>
				<button @click.prevent="guardarNuevoAutor"
				        class="btn btn-success">
					<i class="fa fa-check"></i>
					Aceptar
				</button>
			</div>
		</template>
	</r-modal>

	<r-modal ref="modalNuevoTema" @on-close="cerrarModalNuevoTema">
		<template #title>Añadir tema</template>
		<template #body>
			<div class="p-3">
				<r-input type="text"
				         style-fill
				         placeholder="Nombre"
				         v-model="formularioTema.info.nombre"
				         :disabled="guardandoAutorTema"
				         @keyup.enter.stop="guardarNuevoTema"/>
				<small class="text-danger" v-html="formularioTema.errores.nombre"/>
			</div>
		</template>
		<template #footer="{modal}">
			<div class="w-100 d-flex justify-content-between">
				<button @click.prevent="modal.close"
				        class="btn btn-danger">
					<i class="fa fa-times"></i>&nbsp;Cancelar
				</button>
				<button @click.prevent="guardarNuevoTema"
				        class="btn btn-success">
					<i class="fa fa-check"></i>
					Aceptar
				</button>
			</div>
		</template>
	</r-modal>
</template>
<script setup lang="ts">
import {useNotify} from "base-app/dist/composable";
import {clearErrors, equals, showErrors} from "base-app/dist/utils";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {IFormulario} from "../../interfaces/IFormulario.ts";
import {Libros} from "../../api/Libros.ts";
import {RInput, RModal, RMultipleSelect, RPanelContainer} from "base-app/dist/components";
import {ILibro} from "../../interfaces/ILibro.ts";
import {ITema} from "../../interfaces/ITema.ts";
import {IAutor} from "../../interfaces/IAutor.ts";
import SelectorMultimedia from "../../components/SelectorMultimedia.vue";
import {Autores} from "../../api/Autores.ts";
import {Temas} from "../../api/Temas.ts";

const breadcrumb = computed(() => ([
	{title: 'Libros', name: 'libros'},
	{title: tipoFormulario.value + ' libro'},
]));

const notify = useNotify();
const router = useRouter();
const route = useRoute();

const selectorMultimedia = ref<typeof SelectorMultimedia>();
const modalNuevoAutor = ref<typeof RModal>();
const modalNuevoTema = ref<typeof RModal>();
const cargando = ref<boolean>(false);
const guardando = ref<boolean>(false);
const guardandoAutorTema = ref<boolean>(false);
const errorCargaImagen = ref<boolean>(false);
const autores = ref<IAutor[]>([]);
const temas = ref<ITema[]>([]);
const ultimosDatosFormulario = ref<ILibro>({
	ano: '',
	autores: [],
	dificultad_acceso: '',
	editorial: '',
	isbn: '',
	lugar: '',
	resumen: '',
	ruta_imagen: '',
	temas: [],
	titulo: '',
	url: '',
});
const formularioLibro = ref<IFormulario<ILibro>>({
	info: {
		ano: '',
		autores: [],
		dificultad_acceso: '',
		editorial: '',
		isbn: '',
		lugar: '',
		resumen: '',
		ruta_imagen: '',
		temas: [],
		titulo: '',
		url: '',
	},
	errores: {
		ano: [],
		autores: [],
		dificultad_acceso: [],
		editorial: [],
		isbn: [],
		lugar: [],
		resumen: [],
		ruta_imagen: [],
		temas: [],
		titulo: [],
		url: [],
	}
});

const formularioAutor = ref<IFormulario<IAutor>>({
	info: {
		nombre: '',
	},
	errores: {
		autor: [],
	}
});
const formularioTema = ref<IFormulario<ITema>>({
	info: {
		nombre: '',
	},
	errores: {
		autor: [],
	}
});

onMounted(async () => {
	await cargarInformacionFormulario();
	await cargarLibro();
});

const cargarInformacionFormulario = async () => {
	cargando.value = true;
	try {
		const {data: _autores} = await Autores.lista();
		const {data: _temas} = await Temas.lista();
		autores.value = _autores;
		temas.value = _temas;
	} catch {
		notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
		volverAtras();
	} finally {
		cargando.value = false;
	}
};

const abrirModalMultimedia = () => {
	selectorMultimedia.value?.open();
};

const abrirModalNuevoAutor = () => {
	modalNuevoAutor.value?.open();
};
const cerrarModalNuevoAutor = () => {
	formularioAutor.value.info.nombre = '';
};

const abrirModalNuevoTema = () => {
	modalNuevoTema.value?.open();
};
const cerrarModalNuevoTema = () => {
	formularioTema.value.info.nombre = '';
};

const guardarNuevoAutor = async () => {
	guardandoAutorTema.value = true;
	clearErrors(formularioAutor.value.errores);
	try {
		const {data} = await Autores.nuevo({
			nombre: formularioAutor.value.info.nombre
		});
		if (data.estado) {
			const info = {
				id: data.info.id,
				nombre: data.info.nombre,
			};
			autores.value.push(info);
			formularioLibro.value.info.autores.push(info.id as string);
			notify.success('Autor creado correctamente.');
			cerrarModalNuevoAutor();
			modalNuevoAutor.value?.close();
		} else {
			showErrors(data.mensajes, formularioAutor.value.errores);
		}
	} catch {
		notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
	} finally {
		guardandoAutorTema.value = false;
	}
};

const guardarNuevoTema = async () => {
	guardandoAutorTema.value = true;
	clearErrors(formularioTema.value.errores);
	try {
		const {data} = await Temas.nuevo({
			nombre: formularioAutor.value.info.nombre
		});
		if (data.estado) {
			const info = {
				id: data.info.id,
				nombre: data.info.nombre,
			};
			temas.value.push(info);
			formularioLibro.value.info.temas.push(info.id as string);
			notify.success('Tema creado correctamente.');
			cerrarModalNuevoTema();
			modalNuevoTema.value?.close();
		} else {
			showErrors(data.mensajes, formularioTema.value.errores);
		}
	} catch {
		notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
	} finally {
		guardandoAutorTema.value = false;
	}
};

const cargarLibro = async () => {
	if (route.params.id) {
		cargando.value = true;
		try {
			const {data} = await Libros.porId(route.params.id as string);
			if (data.estado) {
				formularioLibro.value.info.ano = data.info.ano;
				formularioLibro.value.info.autores = data.info.autores;
				formularioLibro.value.info.dificultad_acceso = data.info.dificultad_acceso;
				formularioLibro.value.info.editorial = data.info.editorial;
				formularioLibro.value.info.isbn = data.info.isbn;
				formularioLibro.value.info.lugar = data.info.lugar;
				formularioLibro.value.info.resumen = data.info.resumen;
				formularioLibro.value.info.ruta_imagen = data.info.ruta_imagen;
				formularioLibro.value.info.temas = data.info.temas;
				formularioLibro.value.info.titulo = data.info.titulo;
				formularioLibro.value.info.url = data.info.url;
				ultimosDatosFormulario.value = JSON.parse(JSON.stringify(formularioLibro.value.info));
			} else {
				notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
				volverAtras();
			}
		} catch {
			notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
			volverAtras();
		} finally {
			cargando.value = false;
		}
	}
};

const guardar = async () => {
	guardando.value = true;
	clearErrors(formularioLibro.value.errores);
	try {
		let data;
		if (route.params.id) {
			formularioLibro.value.info.id = route.params.id as string;
			const resultado = await Libros.actualizar(formularioLibro.value.info);
			data = resultado.data;
		} else {
			const resultado = await Libros.nuevo(formularioLibro.value.info);
			data = resultado.data;
		}
		if (data.estado) {
			notify.success('Datos guardados con exito');
			volverAtras();
		} else {
			showErrors(data.mensajes, formularioLibro.value.errores);
		}
	} catch {
		notify.error('Error al intentar guardar');
		volverAtras();
	} finally {
		guardando.value = false;
	}
}
const volverAtras = () => {
	router.push({
		name: 'libros',
	});
};

const deshabilitarFormulario = computed(() => {
	return guardandoAutorTema.value === true || guardando.value === true || equals(ultimosDatosFormulario.value, formularioLibro.value.info);
});

const tipoFormulario = computed(() => {
	return route.params.id ? 'Editar' : 'Nuevo';
});
</script>