<template>
	<div class="container-fluid">
		<div class="row">
			<div v-for="(el, key) in resumen"
			     class="col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-4">
				<div class="hover-shadow">
					<r-card class="hover-shadow-target shadow-none h-100"
					        :class="{'placeholder placeholder-wave': cargando}">
						<template #header>
							<div v-if="!cargando" class="w-100">
								<div class="text-center py-4">
									<div class="d-block display-6 m-0 mb-2">
										<r-number-increment v-model="el.cantidad"/>
									</div>
									<div class="d-block">
										{{ textos[key] }}
									</div>
								</div>
							</div>
							<div v-else class="w-100">
								<div class="w-100 text-center py-5">
									<div class="d-block small">
										<r-spinner class="text-primary"/>
									</div>
									<div class="d-block h6 m-0">
										Cargando
									</div>
								</div>
							</div>
						</template>
					</r-card>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import {RCard, RNumberIncrement, RSpinner} from 'base-app/dist/components';
import {onMounted, ref} from "vue";
import {IDashboardInfo} from "../../interfaces/IDashboardInfo.ts";
import {Dashboard} from "../../api/Dashboard.ts";
import {useNotify} from "base-app/dist/composable";

const notify = useNotify();

const textos = {
	tokens: 'Tokens',
	bases_datos: 'Bases de datos',
	disciplinas: 'Disciplinas',
	libros: 'Libros',
	autores: 'Autores',
	temas: 'Temas',
};

const cargando = ref<boolean>(true);
const resumen = ref<IDashboardInfo>({
	tokens: {cantidad: 0},
	bases_datos: {cantidad: 0},
	disciplinas: {cantidad: 0},
	libros: {cantidad: 0},
	autores: {cantidad: 0},
	temas: {cantidad: 0},
});

onMounted(async () => {
	await cartarDatosDashboard();
});

const cartarDatosDashboard = async () => {
	cargando.value = true;
	try {
		const {data} = await Dashboard.datos();
		if (data.estado) {
			resumen.value = data.info;
		} else {
			notify.error('Ha ocurrido un error, intentalo más tarde.');
		}
	} catch {
		notify.error('Ha ocurrido un error, intentalo más tarde.');
	} finally {
		cargando.value = false;
	}
};

</script>