import {RouteRecordRaw} from "vue-router";
import Lista from "../pages/disciplinas/Disciplinas.vue";
import FormularioDisciplinas from "../pages/disciplinas/FormularioDisciplinas.vue";

export const Disciplinas: RouteRecordRaw[] = [
    {
        name: 'disciplinas',
        path: '/disciplinas',
        component: Lista,
        meta: {title: 'Disciplinas'}
    },
    {
        name: 'disciplinas.nuevo',
        path: '/disciplinas/nuevo',
        component: FormularioDisciplinas,
        meta: {title: 'Nueva disciplinas'}
    },
    {
        name: 'disciplinas.editar',
        path: '/disciplinas/:id',
        component: FormularioDisciplinas,
        meta: {title: 'Editar disciplinas'}
    },
];