import {IResultadoApi} from "../interfaces/IResultadoApi.ts";
import {Request} from "../commons/request.ts";
import {IDisciplina} from "../interfaces/IDisciplina.ts";
import {IResultadoElemento} from "../interfaces/IResultadoElemento.ts";
import {IInformacionPaginacion} from "../interfaces/IInformacionPaginacion.ts";
import {IPaginationResponse} from "base-app/dist/interfaces";


export const Disciplinas = {
    lista: async () => {
        return Request.post<IDisciplina[]>({
            url: `/disciplinas/lista`,
        });
    },
    nuevo: async (disciplina: IDisciplina) => {
        return Request.post<IResultadoElemento<IDisciplina>>({
            url: `/disciplinas/nuevo`,
            data: disciplina
        });
    },
    actualizar: async (disciplina: IDisciplina) => {
        return Request.post<IResultadoElemento<IDisciplina>>({
            url: `/disciplinas/editar`,
            data: disciplina
        });
    },
    paginacion: async (informacion: IInformacionPaginacion) => {
        return Request.post<IPaginationResponse<IDisciplina>>({
            url: `/disciplinas/paginacion`,
            data: informacion
        });
    },
    porId: async (id: string) => {
        return Request.post<IResultadoElemento<IDisciplina>>({
            url: `/disciplinas/id`,
            data: {id}
        });
    },
    deshabilitar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/disciplinas/deshabilitar`,
            data: {id}
        });
    },
    reestablecer: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/disciplinas/restaurar`,
            data: {id}
        });
    },
    eliminar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/disciplinas/eliminar`,
            data: {id}
        });
    },
};