<template>
	<r-panel-container :title="`${tipoFormulario} disciplina`"
	                   icon="far fa-code-branch"
	                   :breadcrumb="breadcrumb">
		<template #toolbar>
			<button @click.prevent="guardar"
			        class="btn btn-dark"
			        :disabled="deshabilitarFormulario">
				<template v-if="!guardando">
					<i class="fa fa-save"></i>
					Guardar
				</template>
				<template v-else>
					<i class="fa fa-sync fa-spin"></i>
					Guardando
				</template>
			</button>
		</template>
		<template #body>
			<div class="row">
				<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 pt-3">
					<div class="mb-4">
						<r-input v-model="formularioDisciplina.info.nombre"
						         style-fill
						         :disabled="cargando || guardando"
						         placeholder="Nombre"/>
						<small class="text-danger" v-html="formularioDisciplina.errores.nombre"/>
					</div>
				</div>
			</div>
		</template>
	</r-panel-container>
</template>
<script setup lang="ts">
import {useNotify} from "base-app/dist/composable";
import {clearErrors, equals, showErrors} from "base-app/dist/utils";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {IFormulario} from "../../interfaces/IFormulario.ts";
import {Disciplinas} from "../../api/Disciplinas.ts";
import {IDisciplina} from "../../interfaces/IDisciplina.ts";
import {RInput, RPanelContainer} from "base-app/dist/components";

const breadcrumb = computed(() => ([
	{title: 'Disciplinas', name: 'disciplinas'},
	{title: tipoFormulario.value + ' disciplina'},
]));

const notify = useNotify();
const router = useRouter();
const route = useRoute();

const cargando = ref<boolean>(false);
const guardando = ref<boolean>(false);

const ultimosDatosFormulario = ref<IDisciplina>({
	nombre: '',
});
const formularioDisciplina = ref<IFormulario<IDisciplina>>({
	info: {
		nombre: '',
	},
	errores: {
		nombre: [],
	}
});

onMounted(async () => {
	await cargarDisciplina();
});

const cargarDisciplina = async () => {
	if (route.params.id) {
		cargando.value = true;
		try {
			const {data} = await Disciplinas.porId(route.params.id as string);
			if (data.estado) {
				formularioDisciplina.value.info.nombre = data.info.nombre;
				ultimosDatosFormulario.value = JSON.parse(JSON.stringify(formularioDisciplina.value.info));
			} else {
				notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
				volverAtras();
			}
		} catch {
			notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
			volverAtras();
		} finally {
			cargando.value = false;
		}
	}
};

const guardar = async () => {
	guardando.value = true;
	clearErrors(formularioDisciplina.value.errores);
	try {
		let data;
		if (route.params.id) {
			formularioDisciplina.value.info.id = route.params.id as string;
			const resultado = await Disciplinas.actualizar(formularioDisciplina.value.info);
			data = resultado.data;
		} else {
			const resultado = await Disciplinas.nuevo(formularioDisciplina.value.info);
			data = resultado.data;
		}
		if (data.estado) {
			notify.success('Datos guardados con exito');
			volverAtras();
		} else {
			showErrors(data.mensajes, formularioDisciplina.value.errores);
		}
	} catch {
		notify.error('Error al intentar guardar');
		volverAtras();
	} finally {
		guardando.value = false;
	}
}
const volverAtras = () => {
	router.push({
		name: 'disciplinas',
	});
};

const deshabilitarFormulario = computed(() => {
	return cargando.value === true || guardando.value === true || equals(ultimosDatosFormulario.value, formularioDisciplina.value.info);
});

const tipoFormulario = computed(() => {
	return route.params.id ? 'Editar' : 'Nueva';
});
</script>