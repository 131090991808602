<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<div class="card-text">
							<div class="card-title h5">Multimedia</div>
						</div>
					</div>
					<div class="card-body">
						<template v-if="!propiedadesAbiertas">
							<div class="row">
								<div class="col-12 col-xxl-7 col-xl-7 col-lg-6 col-md-12 col-sm-12">
									<div class=" mb-3">
										<r-file-input v-model="archivosSeleccionados"
										              @update="subirArchivos"
										              class="btn btn-primary mb-0 me-1"
										              mode="button"
										              multiple/>
										<button class="btn btn-primary mb-0 me-1"
										        :disabled="cargando"
										        @click="openNewFolderForm">
											<i class="fa fa-folder"></i>
											Crear carpeta
										</button>
									</div>
								</div>
								<div class="col-12 col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-sm-12">
									<r-input type="text"
									         style-fill
									         :placeholder="textoEtiquetaBusqueda"
									         v-model="textoBusqueda"
									         :disabled="cargando"
									         class=""
									         @keyup.enter.stop="abrirCarpeta(rutaCarpetaActual)">
										<template #buttons>
											<button class="btn border-0 h-100"
											        :disabled="cargando"
											        @click="abrirCarpeta(rutaCarpetaActual)">
												<i class="fal fa-search"></i>
											</button>
										</template>
									</r-input>
								</div>
							</div>
							<div class="row mb-3">
								<div class="col-12">
									<ol class="breadcrumb-arrow">
										<li class="breadcrumb-item">
											<a href="#"
											   :class="{disabled: cargando}"
											   @click.prevent="openFolderFromBreadcrumb('')">
												<i class="fa fa-home"/>
												Raíz
											</a>
										</li>
										<template v-for="item in enlacesNavegacion">
											<li class="breadcrumb-item">
												<a href="#"
												   :class="{disabled: cargando}"
												   @click.prevent="openFolderFromBreadcrumb(item.path)">
													<small v-html="item.name"></small>
												</a>
											</li>
										</template>
									</ol>
								</div>
								<div class="col-12">
									<div class="progress" v-if="subiendoArchivo">
										<div class="progress-bar progress-bar-striped progress-bar-animated"
										     role="progressbar" :style="{width: porcentajeSubido + '%'}"></div>
									</div>
								</div>
							</div>
							<div class="row file-manager" v-if="cargando">
								<div v-for="_ in 12"
								     class="col-xl-2 col-lg-2 col-md-3 col-sm-6 mb-3">
									<div class="file-box py-1 h-auto">
										<div class="file-img-box mb-0 placeholder placeholder-wave w-100">
											<svg-file-icon text="..." class="file-img"/>
										</div>
										<div class="w-100 placeholder placeholder-wave"></div>
										<small class="w-75 placeholder placeholder-wave"></small>
									</div>
								</div>
							</div>
							<div class="row file-manager">
								<div class="col-12">
									<div class="row">
										<template v-if="resultados.carpetas.length > 0">
											<div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 mb-3"
											     v-for="(carpeta, index) in resultados.carpetas">
												<div class="file-box animate__animated"
												     :class="{'animate__bounceInDown': carpeta.nuevo, 'animate__bounceOutUp': carpeta.eliminado}"
												     @dblclick.prevent="abrirCarpeta(carpeta.ruta)"
												     @contextmenu.prevent="openContextMenu($event, index, carpeta, Type.carpeta)">
													<div class="file-img-box">
														<svg-folder-icon :files="carpeta.archivos" class="file-img"/>
													</div>
													<div class="text-overflow user-select-none"
													     v-html="carpeta.nombre"></div>
												</div>
											</div>
										</template>
										<template v-if="resultados.archivos.length > 0">
											<div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 mb-3"
											     v-for="(archivo, index) in resultados.archivos">
												<div class="file-box animate__animated"
												     :class="{'animate__bounceInDown': archivo.nuevo, 'animate__bounceOutUp': archivo.eliminado}"
												     @dblclick="abrirPropiedades(archivo, index)"
												     @contextmenu.prevent="openContextMenu($event, index, archivo, Type.archivo)">
													<div v-if="isImage(archivo.extension)"
													     class="file-img-box file-image-cover"
													     :style="`background-image: url(${archivo.ruta})`"/>
													<div v-else
													     class="file-img-box">
														<svg-file-icon :text="archivo.extension" class="file-img"/>
													</div>
													<div class="text-overflow user-select-none">
														{{ archivo.nombre }}
														<p class="mb-0"><small v-html="archivo.tamano"></small></p>
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
								<div class="col-12">
									<template
										v-if="!cargando && resultados.carpetas.length === 0 && resultados.archivos.length === 0">
										<div class="alert alert-warning">
											Carpeta vacia
										</div>
										<r-file-input v-model="archivosSeleccionados"
										              @update="subirArchivos"
										              mode="draganddrop"
										              multiple/>
									</template>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="row file-properties">
								<div
									class="col-xl-3 col-lg-3 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
									<div class="icon mb-3" v-if="!isImage(propiedadesArchivo.extension)">
										<svg-file-icon class="mx-auto d-block" style="width: 40%"
										               :text="propiedadesArchivo.extension"/>
									</div>
									<r-image v-else class="img-fluid"
									         :src="propiedadesArchivo.ruta + '?' + Date.now()"/>
								</div>
								<div class="col-xl-9 col-lg-9 col-md-5 col-sm-12">
									<div class="row">
										<div class="col-12">
											<r-input v-model="propiedadesArchivo.ruta"
											         style-fill
											         class="mb-3"
											         readonly
											         placeholder="Enlace"/>
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<r-input v-model="propiedadesArchivo.nombre"
											         style-fill
											         class="mb-3"
											         readonly
											         placeholder="Nombre"/>
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<r-input v-model="propiedadesArchivo.tamano"
											         style-fill
											         class="mb-3"
											         readonly
											         placeholder="Tamaño"/>
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<r-input v-model="propiedadesArchivo.fecha"
											         style-fill
											         class="mb-3"
											         readonly
											         placeholder="Fecha de subida"/>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
					<div v-if="propiedadesAbiertas" class="card-footer">
						<div class="row">
							<div class="col-12">
								<div class="d-flex justify-content-between">
									<button class="btn btn-success btn-sm"
									        @click="cerrarPropiedades">
										<i class="fa fa-chevron-left"></i>
										Volver
									</button>
									<button class="btn btn-danger btn-sm"
									        @click="eliminarDesdePropiedades">
										<i class="fa fa-trash"></i>
										Eliminar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<r-modal ref="modalNuevaCarpeta">
		<template #body>
			<div class="p-3">
				<r-input type="text"
				         style-fill
				         placeholder="Nombre de la carpeta"
				         v-model="nombreNuevaCarpeta"
				         @keyup.enter.stop="createNewFolder"/>
			</div>
		</template>
		<template #footer>
			<div class="w-100 d-flex justify-content-between">
				<button @click.prevent="closeNewFolderForm"
				        class="btn btn-danger">
					<i class="fa fa-times"></i>&nbsp;Cancelar
				</button>
				<button @click.prevent="createNewFolder"
				        class="btn btn-success">
					<i class="fa fa-check"></i>
					Aceptar
				</button>
			</div>
		</template>
	</r-modal>
</template>

<script setup lang="ts">
import {computed, onMounted, PropType, ref} from "vue";
import {useContextMenu, useDialog, useNotify} from "base-app/dist/composable";
import {isEmpty, sluggify} from "base-app/dist/utils";
import {RFileInput, RImage, RInput, RModal} from "base-app/dist/components";
import SvgFolderIcon from "../../components/SvgFolderIcon.vue";
import SvgFileIcon from "../../components/SvgFileIcon.vue";
import {Multimedia} from "../../api/Multimedia.ts";
import {IMenuContextualMultimedia} from "../../interfaces/IMenuContextualMultimedia.ts";
import {IArchivoMultimedia, ICarpetaMultimedia} from "../../interfaces/IArchivoMultimedia.ts";
import {IDirectorio} from "../../interfaces/IDirectorio.ts";

enum Type {
	archivo,
	carpeta
}

const notify = useNotify();
const dialog = useDialog();
const contextmenu = useContextMenu();

const props = defineProps({
	fileTypes: {
		type: Array as PropType<string[]>,
		default: () => ([]),
	}
});

const archivosSeleccionados = ref<FileList>();

const modalNuevaCarpeta = ref<typeof RModal>();

const cargando = ref<boolean>(false);
const rutaCarpetaActual = ref<string>('');
const propiedadesAbiertas = ref<boolean>(false);
const nombreNuevaCarpeta = ref<string>('');
const subiendoArchivo = ref<boolean>(false);
const porcentajeSubido = ref<number>(0);
const textoBusqueda = ref<string>('');
const resultados = ref<IDirectorio>({
	carpetas: [],
	archivos: []
});
const propiedadesArchivo = ref<IArchivoMultimedia>({
	nombre: '',
	tamano: '',
	ruta: '',
	extension: '',
	fecha: '',
	indice: '',
	nuevo: false,
	eliminado: false,
});
const datosMenuContextual = ref<IMenuContextualMultimedia>({
	indice: -1,
	elemento: null,
	tipo: null,
});

onMounted(async () => {
	await abrirCarpeta('')
});

const openContextMenu = (event: MouseEvent, index: number, item: IArchivoMultimedia | ICarpetaMultimedia, type: Type, showProperties: boolean = true) => {
	event.preventDefault();
	let items = [];
	if (type === Type.archivo) {
		items.push({
			label: 'Abrir',
			icon: 'fa fa-external-link-alt',
			onClick: () => window.open(item.ruta, '_blank')?.focus()
		});
	} else if (type === Type.carpeta) {
		items.push({
			label: 'Abrir',
			icon: 'fa fa-folder',
			onClick: async () => await abrirCarpeta(item.ruta)
		});
	}

	items.push({
		label: 'Eliminar',
		icon: 'fal fa-trash',
		onClick: eliminarDesdeMenuContextual
	});
	if (showProperties) {
		items.push({
			label: 'Propiedades',
			icon: 'fal fa-list-ul',
			onClick: accionAbrirDesdePropuedades
		});
	}
	asignarDatosMenuContextual(index, item, type);
	contextmenu.show({
		x: event.x,
		y: event.y,
		items,
		zIndex: 9999
	});
}
const isImage = (extension: string) => {
	return ['png', 'jpg', 'pneg', 'jpeg'].indexOf(extension.toLowerCase()) !== -1;
}

const openNewFolderForm = () => {
	modalNuevaCarpeta.value?.open();
}
const closeNewFolderForm = () => {
	modalNuevaCarpeta.value?.close();
	nombreNuevaCarpeta.value = '';
}
const createNewFolder = async () => {
	if (!isEmpty(nombreNuevaCarpeta.value)) {
		const newFolder = sluggify(nombreNuevaCarpeta.value);
		try {
			const {data} = await Multimedia.crear_carpeta({
				path: rutaCarpetaActual.value,
				folderName: newFolder,
			});
			if (data.estado) {
				resultados.value.carpetas.unshift({
					nombre: newFolder,
					ruta: [rutaCarpetaActual.value, newFolder].join('/'),
					archivos: 0,
					indice: resultados.value.carpetas.length,
					nuevo: true,
					eliminado: false
				});
				notify.success(`Carpeta <b>${newFolder}</b> creada`);
			} else {
				notify.error('Error al intentar crear la carpeta');
			}
			closeNewFolderForm();
		} catch (e) {
			console.log(e)
			notify.error('Error al intentar crear la carpeta');
		} finally {
			cargando.value = false;
		}
	}
}
const openFolderFromBreadcrumb = (path: string) => {
	if (!cargando.value) {
		textoBusqueda.value = '';
		abrirCarpeta(path);
	}
}
const abrirCarpeta = async (path: string) => {
	cargando.value = true;
	resultados.value.carpetas = [];
	resultados.value.archivos = [];
	rutaCarpetaActual.value = path;
	try {
		const {data} = await Multimedia.leer_directorio({
			path: rutaCarpetaActual.value,
			query: textoBusqueda.value,
			filetypes: props.fileTypes,
		});
		resultados.value.carpetas = data.carpetas.map(e => ({
			...e,
			nuevo: false,
			elimnado: false,
		}));
		resultados.value.archivos = data.archivos.map(e => ({
			...e,
			nuevo: false,
			elimnado: false,
		}));
	} catch {
		notify.error('Error al intentar cargar archivos y carperas');
	} finally {
		cargando.value = false;
	}
}
const subirArchivos = async () => {
	const files = archivosSeleccionados.value;
	if (files != undefined && files.length > 0) {
		subiendoArchivo.value = true;
		try {
			const {data} = await Multimedia.subir_archivo({
				ruta: rutaCarpetaActual.value
			}, files, (progress) => {
				porcentajeSubido.value = progress;
			});
			for (let i = 0; i < data.uploads.length; i++) {
				let item = {
					extension: data.uploads[i].extension,
					ruta: data.uploads[i].ruta,
					nombre: data.uploads[i].nombre,
					tamano: data.uploads[i].tamano,
					fecha: data.uploads[i].fecha,
					nuevo: true,
					eliminado: false,
				};
				resultados.value.archivos.unshift(item);
			}
			setTimeout(() => {
				resultados.value.archivos.filter(f => f.nuevo).forEach((f) => {
					f.nuevo = false;
				});
			}, 600);
			subiendoArchivo.value = false;
			notify.success(`Archivo${files.length === 1 ? 'el' : 'los'} subido${files.length === 1 ? 'el' : 'los'} correctamente`);
		} catch (e) {
			notify.error(`Error al intentar subir ${files.length === 1 ? 'el' : 'los'} archivo${files.length === 1 ? '' : 's'}.`);
		} finally {
			archivosSeleccionados.value = undefined;
			subiendoArchivo.value = false;
		}
	}
}
const eliminarArchivoCarperta = (el: IMenuContextualMultimedia) => {
	const isFolder = el.tipo === Type.carpeta;
	cerrarMenuContextual();
	let title: string;
	let text: string;
	if (isFolder) {
		title = 'Eliminar carpeta';
		text = 'la carpeta <b>' + el.elemento?.nombre + '</b>';
	} else {
		title = 'Eliminar archivo';
		text = 'el archivo <b>' + el.elemento?.nombre + '</b>';
	}
	dialog.confirm({
		title: title,
		message: '¿Seguro de eliminar ' + text + ' ?',
		async okHandler(_, closeHandler) {
			try {
				const {data} = await Multimedia.eliminar({
					nombre: el.elemento?.nombre || '',
					tipo: isFolder ? 'folder' : 'file',
					ruta: rutaCarpetaActual.value
				});
				if (data.estado === true) {
					notify.success('Elemento eliminado con exito.');
					if (isFolder) {
						let indexToDelete = resultados.value.carpetas.findIndex((_, index) => {
							return el.indice === index;
						});
						if (indexToDelete >= 0) {
							resultados.value.carpetas[indexToDelete].eliminado = true;
							setTimeout(() => {
								resultados.value.carpetas.splice(indexToDelete, 1);
							}, 600);
						}
					} else {
						let indexToDelete = resultados.value.archivos.findIndex((_, index) => {
							return el.indice === index;
						});
						if (indexToDelete >= 0) {
							resultados.value.archivos[indexToDelete].eliminado = true;
							setTimeout(() => {
								resultados.value.archivos.splice(indexToDelete, 1);
							}, 600);
						}
					}
				} else {
					notify.error('Error al intentar eliminar');
				}
			} catch (e) {
				notify.error('Error al intentar eliminar');
			} finally {
			}
			closeHandler();
		},
		cancelHandler() {
		},
	});
}
const abrirPropiedades = (archivo: IArchivoMultimedia, index: number) => {
	propiedadesArchivo.value.nombre = archivo.nombre;
	propiedadesArchivo.value.tamano = archivo.tamano;
	propiedadesArchivo.value.ruta = archivo.ruta;
	propiedadesArchivo.value.extension = archivo.extension;
	propiedadesArchivo.value.fecha = archivo.fecha;
	propiedadesArchivo.value.indice = index;
	propiedadesAbiertas.value = true;
}
const cerrarPropiedades = () => {
	propiedadesAbiertas.value = false;
}
const eliminarDesdePropiedades = () => {
	eliminarArchivoCarperta({
		indice: propiedadesArchivo.value.indice,
		elemento: propiedadesArchivo.value,
		tipo: Type.archivo,
	} as IMenuContextualMultimedia);
	cerrarPropiedades();
}
const asignarDatosMenuContextual = (index: number, item: IArchivoMultimedia | ICarpetaMultimedia, type: any) => {
	datosMenuContextual.value = {
		indice: index,
		elemento: item,
		tipo: type,
	};
}

const cerrarMenuContextual = () => {
	datosMenuContextual.value = {
		indice: -1,
		elemento: null,
		tipo: null,
	};
}
const accionAbrirDesdePropuedades = () => {
	if (datosMenuContextual.value.tipo === 'folder') {
		abrirCarpeta((datosMenuContextual.value.elemento as ICarpetaMultimedia).ruta);
	} else if (datosMenuContextual.value.tipo === 'file') {
		abrirPropiedades(datosMenuContextual.value.elemento as IArchivoMultimedia, datosMenuContextual.value.indice);
	}
	cerrarMenuContextual();
}

const eliminarDesdeMenuContextual = () => {
	eliminarArchivoCarperta(datosMenuContextual.value);
	cerrarMenuContextual();
}

const enlacesNavegacion = computed(() => {
	let breadcrumb = [];
	if (rutaCarpetaActual.value !== '') {
		let split = rutaCarpetaActual.value.replace('\\', '/').replace(/^\/+|\/+$/g, '').split('/').reverse();
		for (let i = split.length - 1; i >= 0; i--) {
			breadcrumb[i] = {
				path: split.slice().splice(i, split.length).reverse().join('/'),
				name: split[i]
			};
		}
	}
	return breadcrumb.reverse();
});

const textoEtiquetaBusqueda = computed(() => {
	let folderName = 'Raiz';
	if (rutaCarpetaActual.value !== '') {
		let split = rutaCarpetaActual.value.replace('\\', '/').replace(/^\/+|\/+$/g, '').split('/');
		folderName = split[split.length - 1];
	}
	return 'Buscar en: ' + folderName;
});

</script>