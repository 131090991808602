<template>
	<div class="container-fluid h-100">
		<div class="row h-100">
			<div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 mb-5">
				<r-card class="shadow h-100">
					<template #header>
						<div class="card-text w-100 d-flex justify-content-between align-items-center">
							<div class="h5 card-title">Mi perfíl</div>
							<button @click="guardarPerfil"
							        class="btn btn-success btn-sm"
							        :disabled="deshabilitarBotonGuardarPerfil">
								<template v-if="!guardandoPerfil">
									<i class="fa fa-save"></i>
									Guardar
								</template>
								<template v-else>
									<i class="fa fa-sync fa-spin"></i>
									Guardando
								</template>
							</button>
						</div>
					</template>
					<template #body>
						<div class="row mt-3">
							<div class="col-12 col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-12 text-center">
								<div class="text-center d-inline-block">
									<div
										class="position-relative hover-toggle rounded-circle overflow-hidden d-inline-block">
										<r-profile-photo class="border-2 border-white border"
										                 :photo="formularioPerfil.info.foto"
										                 :text="getFirstLetters(`${usuario?.nombres} ${usuario?.apellidos}`)"
										                 rounded-circle
										                 :size="150"
										                 :font-size="40"/>
										<div v-if="!formularioPerfil.info.foto"
										     class="d-flex justify-content-center overflow-hidden position-absolute w-100 h-100 rounded-circle bg-dark-op bg-opacity-50 hover-toggle-target top-0 start-0 text-white border-2 border-white border">
											<button
												class="btn btn-outline-dark text-white border-0 rounded-start-circle bg-opacity-25 w-100 d-flex align-items-center justify-content-center"
												:disabled="guardandoPerfil"
												@click.prevent="abrirModalCamara">
						                        <span>
						                          <i class="fal fa-camera"/>
						                          <small class="d-block w-100">Foto</small>
						                        </span>
											</button>
											<label
												class="btn btn-outline-dark text-white border-0 rounded-end-circle bg-opacity-25 w-100 d-flex align-items-center justify-content-center">
												<input type="file"
												       :disabled="guardandoPerfil"
												       @change="cambiarFoto" accept="image/*"
												       class="d-none">
												<span>
						                        <i class="fal fa-computer"/>
						                        <small class="d-block w-100">Imagen</small>
						                        </span>
											</label>
										</div>
										<button v-else
										        :disabled="guardandoPerfil"
										        class="btn position-absolute w-100 h-100 rounded-circle bg-danger-op bg-opacity-75 hover-toggle-target top-0 start-0 text-white border-2 border-white border"
										        @click.prevent="quitarFoto">
											<i class="far fa-trash display-6"/>
										</button>
									</div>
								</div>
							</div>
							<div class="col-12 col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-sm-12 text-center">
								<div class="mb-4">
									<r-input v-model="formularioPerfil.info.nombres"
									         :disabled="guardandoPerfil"
									         style-fill
									         placeholder="Nombre"/>
									<small class="text-danger" v-html="formularioPerfil.errores.nombre"/>
								</div>
								<div class="mb-4">
									<r-input v-model="formularioPerfil.info.apellidos"
									         :disabled="guardandoPerfil"
									         style-fill
									         placeholder="Apellido"/>
									<small class="text-danger" v-html="formularioPerfil.errores.apellidos"/>
								</div>
								<div class="mb-4">
									<r-input v-model="formularioPerfil.info.email"
									         :disabled="guardandoPerfil"
									         style-fill
									         placeholder="Corrreo electrónico"/>
									<small class="text-danger" v-html="formularioPerfil.errores.email"/>
								</div>
							</div>
						</div>
					</template>
				</r-card>
			</div>
			<div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-5">
				<r-card class="shadow h-100">
					<template #header>
						<div class="card-text w-100 d-flex justify-content-between align-items-center">
							<div class="h5 card-title">Contraseña</div>
							<button @click="guardarContrasena"
							        class="btn btn-success btn-sm"
							        :disabled="deshabilitarBotonGuardarContrasena">
								<template v-if="!guardandoContrasena">
									<i class="fa fa-save"></i>
									Guardar
								</template>
								<template v-else>
									<i class="fa fa-sync fa-spin"></i>
									Guardando
								</template>
							</button>
						</div>
					</template>
					<template #body>
						<div class="row mt-3">
							<div class="col-12">
								<div class="mb-4">
									<r-input v-model="formularioContrasena.info.contrasena"
									         style-fill
									         placeholder="Contraseña"
									         password
									         @input="validatePassword"
									         :disabled="guardandoContrasena"/>
									<small class="text-danger" v-html="formularioPerfil.errores.contrasena"/>
									<div class="bg-body-secondary">
										<r-password-strength v-model="formularioContrasena.info.contrasena"
										                     @score="showScore"/>
									</div>
									<small class="text-left w-100 mb-3">{{ passwordLevel }}</small>
								</div>
								<div class="mb-4">
									<r-input v-model="formularioContrasena.info.repetir_contrasena"
									         style-fill
									         placeholder="Repetir contraseña"
									         :password="true"
									         :disabled="guardandoContrasena"/>
									<small class="text-danger"
									       v-html="formularioContrasena.errores.repetir_contrasena"/>
								</div>
								<hr>
								<div class="mb-4">
									<r-input v-model="formularioContrasena.info.contrasena_actual"
									         style-fill
									         placeholder="Contraseña actual"
									         :password="true"
									         :disabled="guardandoContrasena"/>
									<small class="text-danger" v-html="formularioContrasena.errores.contrasena_actual"/>
								</div>
							</div>
						</div>
					</template>
				</r-card>
			</div>
		</div>
	</div>
	<r-modal ref="modalTomarFoto" size="md" @on-close="detenerStreamCamara">
		<template #title>
			Tomar foto
		</template>
		<template #body>
			<video v-show="!fotoTomada"
			       ref="videoTomarFoto" class="w-100" height="240" autoplay/>
			<canvas v-show="fotoTomada"
			        ref="canvasTomarFoto" class="w-100" height="240"/>
		</template>
		<template #footer>
			<button class="btn btn-success" @click="guardarFotoTomada">
				<i class="fa fa-camera"></i>&nbsp;Tomar foto
			</button>
		</template>
	</r-modal>
</template>

<script setup lang="ts">
import {RCard, RInput, RModal, RPasswordStrength, RProfilePhoto} from "base-app/dist/components";
import {computed, ref} from "vue";
import {useNotify, useSession} from "base-app/dist/composable";
import {IUsuario} from "../interfaces/IUsuario.ts";
import {
	clearErrors,
	cropImageToSquare,
	equals,
	fileToBase64,
	getFirstLetters,
	resizeImage,
	showErrors,
	validateEmail
} from "base-app/dist/utils";
import {Usuario} from "../api/Usuario.ts";
import {IFormulario} from "../interfaces/IFormulario.ts";
import {ICambioContrasena} from "../interfaces/ICambioContrasena.ts";

const notify = useNotify();
const session = useSession();
const usuario = session.get<IUsuario>('usuario');

const streamCamara = ref<MediaStream>();
const videoTomarFoto = ref<HTMLVideoElement>();
const canvasTomarFoto = ref<HTMLCanvasElement>();
const modalTomarFoto = ref<typeof RModal>();

const guardandoPerfil = ref(false);
const guardandoContrasena = ref(false);
const passwordLevel = ref('');
const formularioPerfil = ref<IFormulario<IUsuario>>({
	info: {
		id: usuario?.id,
		nombres: usuario?.nombres || '',
		apellidos: usuario?.apellidos || '',
		email: usuario?.email || '',
		foto: usuario?.foto,
	},
	errores: {}
});
const formularioContrasena = ref<IFormulario<ICambioContrasena>>({
	info: {
		contrasena: '',
		repetir_contrasena: '',
		contrasena_actual: '',
	},
	errores: {
		contrasena: [],
		contrasena_actual: [],
	}
});

const abrirModalCamara = async () => {
	modalTomarFoto.value?.open();
	streamCamara.value = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
	if (videoTomarFoto.value) {
		videoTomarFoto.value.srcObject = streamCamara.value;
	}
};
const validatePassword = () => {
	if (formularioContrasena.value.info.contrasena === '') {
		passwordLevel.value = '';
	}
};

const showScore = (meter: any) => {
	switch (meter.score) {
		case 4:
			passwordLevel.value = 'Muy segura';
			break;
		case 3:
			passwordLevel.value = 'Segura';
			break;
		case 2:
			passwordLevel.value = 'Mediana seguridad';
			break;
		case 1:
			passwordLevel.value = 'Débil';
			break;
		case 0:
			passwordLevel.value = 'Muy débil';
			break;
		default:
			passwordLevel.value = '';
			break;
	}
};

const guardarPerfil = async () => {
	guardandoPerfil.value = true;
	clearErrors(formularioPerfil.value.errores);
	try {
		const {data} = await Usuario.actualizar(formularioPerfil.value.info);
		if (!data.estado) {
			showErrors(data.mensajes, formularioPerfil.value.errores);
		} else {
			if (usuario) {
				usuario.nombres = formularioPerfil.value.info.nombres || '';
				usuario.apellidos = formularioPerfil.value.info.apellidos || '';
				usuario.email = formularioPerfil.value.info.email || '';
				usuario.foto = formularioPerfil.value.info.foto;
			}
			notify.success('Pefíl actualizado.');
		}
	} catch (e) {
		console.log(e)
		notify.error('Ha ocurrido un error, favor contacta al administrador.');
	} finally {
		guardandoPerfil.value = false;
	}
};
const guardarContrasena = async () => {
	guardandoContrasena.value = true;
	clearErrors(formularioContrasena.value.errores);
	try {
		const {data} = await Usuario.contrasena(formularioContrasena.value.info);
		if (!data.estado) {
			console.log(data.mensajes)
			showErrors(data.mensajes, formularioContrasena.value.errores);
		} else {
			formularioContrasena.value.info.contrasena = '';
			formularioContrasena.value.info.repetir_contrasena = '';
			formularioContrasena.value.info.contrasena_actual = '';
			notify.success('Contraseña actualizada.');
		}
	} catch (e) {
		console.log(e)
		notify.error('Ha ocurrido un error, favor contacta al administrador.');
	} finally {
		guardandoContrasena.value = false;
	}

};

const guardarFotoTomada = () => {
	if (canvasTomarFoto.value && videoTomarFoto.value) {
		const context = canvasTomarFoto.value.getContext('2d');
		if (context) {
			context.drawImage(videoTomarFoto.value as CanvasImageSource, 0, 0, canvasTomarFoto.value.width, canvasTomarFoto.value.height);
			const base64 = canvasTomarFoto.value.toDataURL('image/jpeg');
			preparePhoto(base64);
			modalTomarFoto.value?.close();
			detenerStreamCamara();
		}
	}
};
const detenerStreamCamara = () => {
	if (videoTomarFoto.value) {
		videoTomarFoto.value.srcObject = null;
	}
	streamCamara.value?.getTracks().forEach(track => track.stop());
};
const cambiarFoto = async (event: Event) => {
	const target = event.target as HTMLInputElement;
	if (target.files?.length) {
		const files = target.files;
		if (files[0].type.indexOf('image') > -1) {
			const {base64} = await fileToBase64(files[0]);
			await preparePhoto(base64);
		} else {
			notify.error('El archivo seleccionado no es una imagen.');
		}
	}
};

const preparePhoto = async (foto: string) => {
	try {
		const {base64: resize} = await resizeImage(foto, 200, 200);
		const {base64: final} = await cropImageToSquare(resize);
		formularioPerfil.value.info.foto = final;
	} catch {
		notify.error('Ha ocurrido un errror, por favor contacta al administrador.');
	}
};

const quitarFoto = () => {
	delete formularioPerfil.value.info.foto;
};

const deshabilitarBotonGuardarPerfil = computed(() => {
	return guardandoPerfil.value === true ||
		equals(formularioPerfil.value.info, usuario) ||
		!validateEmail(formularioPerfil.value.info.email) ||
		(formularioPerfil.value.info.email === '' ||
			formularioPerfil.value.info.nombres === '' ||
			formularioPerfil.value.info.apellidos === '');
});
const deshabilitarBotonGuardarContrasena = computed(() => {
	return guardandoContrasena.value === true ||
		(formularioContrasena.value.info.contrasena === '' ||
			formularioContrasena.value.info.repetir_contrasena === '' ||
			formularioContrasena.value.info.contrasena_actual === '');
});
const fotoTomada = computed(() => {
	if (canvasTomarFoto.value) {
		const context = canvasTomarFoto.value.getContext('2d', {
			willReadFrequently: true
		});
		if (context) {
			const pixelBuffer = new Uint32Array(
				context.getImageData(0, 0, canvasTomarFoto.value.width, canvasTomarFoto.value.height).data.buffer
			);
			return pixelBuffer.some(color => color !== 0);
		}
	}
	return false;
});
</script>
