import {IInformacionInicioSesion} from "../interfaces/IInformacionInicioSesion.ts";
import {IResultadoInicioSesion} from "../interfaces/IResultadoInicioSesion.ts";
import {IUsuario} from "../interfaces/IUsuario.ts";
import {IResultadoApi} from "../interfaces/IResultadoApi.ts";
import {Request} from "../commons/request.ts";
import {IVerificacionToken} from "../interfaces/IVerificacionToken.ts";
import {ICambioContrasena} from "../interfaces/ICambioContrasena.ts";

export const Usuario = {
    verificar_token: async () => {
        const {data} = await Request.post<IVerificacionToken>({
            url: 'verificar-token',
        });
        return data;
    },
    inicio_sesion: async (informacion: IInformacionInicioSesion) => {
        Request.defaults.baseURL = import.meta.env.VITE_APP_URL;
        await Request.get({url: 'sanctum/csrf-cookie'});
        Request.defaults.baseURL = import.meta.env.VITE_APP_API_URL;
        return Request.post<IResultadoInicioSesion>({
            url: `/inicio-sesion`,
            data: informacion
        });
    },
    actualizar: async (usuario: IUsuario) => {
        return Request.post<IResultadoApi>({
            url: `/usuarios/editar`,
            data: usuario
        });
    },
    contrasena: async (datos: ICambioContrasena) => {
        return Request.post<IResultadoApi>({
            url: `/mi-perfil/cambiar-contrasena`,
            data: datos
        });
    },
};