import {IResultadoApi} from "../interfaces/IResultadoApi.ts";
import {Request} from "../commons/request.ts";
import {ILibro} from "../interfaces/ILibro.ts";
import {IResultadoElemento} from "../interfaces/IResultadoElemento.ts";
import {IInformacionPaginacion} from "../interfaces/IInformacionPaginacion.ts";
import {IPaginationResponse} from "base-app/dist/interfaces";

export const Libros = {
    lista: async () => {
        return Request.post<ILibro[]>({
            url: `/libros/lista`,
        });
    },
    nuevo: async (libro: ILibro) => {
        return Request.post<IResultadoElemento<ILibro>>({
            url: `/libros/nuevo`,
            data: libro
        });
    },
    actualizar: async (libro: ILibro) => {
        return Request.post<IResultadoElemento<ILibro>>({
            url: `/libros/editar`,
            data: libro
        });
    },
    paginacion: async (informacion: IInformacionPaginacion) => {
        return Request.post<IPaginationResponse<ILibro>>({
            url: `/libros/paginacion`,
            data: informacion
        });
    },
    porId: async (id: string) => {
        return Request.post<IResultadoElemento<ILibro>>({
            url: `/libros/id`,
            data: {id}
        });
    },
    deshabilitar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/libros/deshabilitar`,
            data: {id}
        });
    },
    reestablecer: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/libros/restaurar`,
            data: {id}
        });
    },
    eliminar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/libros/eliminar`,
            data: {id}
        });
    },
};