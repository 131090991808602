<template>
	<div class="container-fluid h-100 login">
		<div class="row h-100">
			<div class="left-container position-relative col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
				<r-dropdown-theme class="position-absolute top-0 end-0 m-3"/>
				<div class="h-100 d-flex align-items-center justify-content-center">
					<div class="w-50">
						<r-image :src="logo" class="mx-auto d-block mb-5"/>
						<div class="mb-4">
							<r-input v-model="formulario.info.correo"
							         style-fill
							         :disabled="iniciandoSesion"
							         icon="far fa-user"
							         placeholder="Usuario"
							         @keyup.enter="iniciarSesion"/>
							<template v-if="formulario.errores.correo">
								<small v-for="errores in formulario.errores.correo"
								       v-html="errores"/>
							</template>
						</div>
						<div class="mb-4">
							<r-input v-model="formulario.info.contrasena"
							         style-fill
							         icon="far fa-lock"
							         password
							         :disabled="iniciandoSesion"
							         placeholder="Contraseña"
							         @keyup.enter="iniciarSesion"/>
						</div>
						<div class="w-100 d-flex justify-content-between">
							<r-check-box v-model="formulario.info.recordar"
							             :disabled="iniciandoSesion">
								Recordar
							</r-check-box>
							<button class="btn btn-success"
							        :disabled="iniciandoSesion"
							        @click.prevent="iniciarSesion">
								<i v-if="!iniciandoSesion" class="far fa-portal-enter me-2"/>
								<i v-else class="fa fa-spinner-third fa-spin-fast me-2"/>Entrar
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				class="right-container d-none d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none g-0 col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12"/>
		</div>
	</div>
</template>

<script setup lang="ts">
import logo from '../assets/images/logo-cabid.png';
import {onMounted, onUnmounted, ref} from "vue";
import {clearErrors, showErrors} from "base-app/dist/utils";
import {RCheckBox, RDropdownTheme, RImage, RInput} from "base-app/dist/components";
import {IFormulario} from "../interfaces/IFormulario.ts";
import {Usuario} from "../api/Usuario.ts";
import {useRouter} from "vue-router";
import {useNotify, useSession, useThemeMode} from "base-app/dist/composable";
import {IInformacionInicioSesion} from "../interfaces/IInformacionInicioSesion.ts";

const session = useSession();
const router = useRouter();
const notify = useNotify();

useThemeMode();

const iniciandoSesion = ref<boolean>(false);
const formulario = ref<IFormulario<IInformacionInicioSesion>>({
	info: {
		correo: '',
		contrasena: '',
		// correo: 'sebastian.carrasco@uaysen.cl',
		// contrasena: '123',
		recordar: false,
	},
	errores: {}
});

onMounted(() => {
	document.documentElement.classList.add('vh-100');
	document.body.classList.add('vh-100');
	document.getElementById('app')?.classList.add('vh-100');
});
onUnmounted(() => {
	document.documentElement.classList.remove('vh-100');
	document.body.classList.remove('vh-100');
	document.getElementById('app')?.classList.remove('vh-100');
});
const iniciarSesion = async () => {
	iniciandoSesion.value = true;
	clearErrors(formulario.value.errores);
	try {
		const {data} = await Usuario.inicio_sesion(formulario.value.info);
		if (!data.usuario) {
			showErrors(data.mensajes, formulario.value.errores);
			return;
		}
		session.set('usuario', data.usuario);
		await router.push({
			name: 'dashboard',
		});
	} catch {
		notify.error('Ha ocurrido un error, intentalo más tarde.');
	} finally {
		iniciandoSesion.value = false;
	}
};

</script>