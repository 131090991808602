import {IDirectorio} from "../interfaces/IDirectorio.ts";
import {ILecturaDirectorio} from "../interfaces/ILecturaDirectorio.ts";
import {INuevaCarpeta} from "../interfaces/INuevaCarpeta.ts";
import {IEliminacionMultimedia} from "../interfaces/IEliminacionMultimedia.ts";
import {IResultadoApi} from "../interfaces/IResultadoApi.ts";
import {Request} from "../commons/request.ts";

export const Multimedia = {
    leer_directorio: (parametros: ILecturaDirectorio) => {
        return Request.post<IDirectorio>({
            url: `/multimedia/lista`,
            data: parametros
        });
    },
    crear_carpeta: async (parametros: INuevaCarpeta) => {
        return Request.post<IResultadoApi>({
            url: `/multimedia/carpeta`,
            data: parametros
        });
    },
    subir_archivo: async (data: any, archivos: FileList, progreso: (percent: number) => void) => {
        return Request.upload<{ uploads: any[] }>({
            url: `/multimedia/subir`,
            files: archivos,
            data: data,
            inProgress: progreso
        });
    },
    eliminar: async (elemento: IEliminacionMultimedia) => {
        return Request.post<IResultadoApi>({
            url: `/multimedia/eliminar`,
            data: elemento,
        });
    },
};