import {IResultadoApi} from "../interfaces/IResultadoApi.ts";
import {Request} from "../commons/request.ts";
import {IToken} from "../interfaces/IToken.ts";
import {IResultadoElemento} from "../interfaces/IResultadoElemento.ts";
import {IInformacionPaginacion} from "../interfaces/IInformacionPaginacion.ts";
import {IPaginationResponse} from "base-app/dist/interfaces";


export const Tokens = {
    lista: async () => {
        return Request.post<IToken[]>({
            url: `/tokens/lista`,
        });
    },
    nuevo: async (token: IToken) => {
        return Request.post<IResultadoElemento<IToken>>({
            url: `/tokens/nuevo`,
            data: token
        });
    },
    actualizar: async (token: IToken) => {
        return Request.post<IResultadoElemento<IToken>>({
            url: `/tokens/editar`,
            data: token
        });
    },
    paginacion: async (informacion: IInformacionPaginacion) => {
        return Request.post<IPaginationResponse<IToken>>({
            url: `/tokens/paginacion`,
            data: informacion
        });
    },
    porId: async (id: string) => {
        return Request.post<IResultadoElemento<IToken>>({
            url: `/tokens/id`,
            data: {id}
        });
    },
    deshabilitar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/tokens/deshabilitar`,
            data: {id}
        });
    },
    reestablecer: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/tokens/restaurar`,
            data: {id}
        });
    },
    eliminar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/tokens/eliminar`,
            data: {id}
        });
    },
};