import {ref} from "vue";
import {IMetaMenuIItem} from "base-app/dist/interfaces";

const menu = ref<{
    userMenu?: IMetaMenuIItem[],
    mainMenu: IMetaMenuIItem[],
}>({
    userMenu: [
        {title: 'Mi perfíl', name: 'mi.perfil'},
        {title: 'Cerrar sesión', name: 'cerrar.sesion'},
    ],
    mainMenu: [
        {title: 'Panel de control', icon: 'fal fa-dashboard', name: 'dashboard',},
        {title: 'Multimedia', icon: 'fal fa-images', name: 'multimedia',},
        {
            title: 'Bases de datos',
            icon: 'fal fa-laptop-binary',
            children: [
                {title: 'Bases de datos', name: 'bases.datos'},
                {title: 'Disciplinas', name: 'disciplinas'},
            ]
        },
        {
            title: 'Libros',
            icon: 'fal fa-books',
            children: [
                {title: 'Libros', name: 'libros',},
                {title: 'Temas', name: 'temas',},
                {title: 'Autores', name: 'autores',},
            ]
        },
        {title: 'Gestión de token', icon: 'fal fa-key', name: 'tokens'},
    ]
})
export const useMenu = () => {
    return menu;
}