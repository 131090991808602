<template>
	<r-panel-container :title="`${tipoFormulario} token`"
	                   icon="fal fa-key"
	                   :breadcrumb="breadcrumb">
		<template #toolbar>
			<button @click.prevent="guardar"
			        class="btn btn-dark btn-sm"
			        :disabled="deshabilitarFormulario">
				<template v-if="!guardando">
					<i class="fa fa-save"></i>
					Guardar
				</template>
				<template v-else>
					<i class="fa fa-sync fa-spin"></i>
					Guardando
				</template>
			</button>
		</template>
		<template #body>
			<div class="row">
				<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
					<div class="mb-4">
						<r-input v-model="formularioConexion.info.nombre"
						         style-fill
						         :disabled="cargando || guardando"
						         placeholder="Nombre"/>
						<small class="text-danger" v-html="formularioConexion.errores.nombre"/>
					</div>
				</div>
			</div>
			<r-fieldset class="mt-4">
				<template #title>
					<i class="fal fa-laptop-binary me-2"/>Bases de datos
				</template>
				<template #content>
					<div class="row mb-3">
						<div class="col-12">
							<r-simple-select v-model="letraSeleccionada"
							                 style-fill
							                 :disabled="cargando || guardando"
							                 :options="letras"/>
						</div>

					</div>
					<div class="row">
						<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
							<div class="h6">Bases de datos Disponibles</div>
							<r-card class="my-3 border-1 border-solid border-primary">
								<template #body>
									<div class="overflow-y-auto overflow-x-hidden" style="height: 300px">
										<template v-if="basesDatosPorLetra.length > 0">
											<div v-for="db in basesDatosPorLetra"
											     class="m-2 d-flex justify-content-between align-items-center">
												<button class="btn btn-info"
												        @click="mostrarInfo(db)">
													<i class="fa fa-info fa-fw"/>
												</button>
												<div class="mx-2 w-100">{{ db.nombre }}</div>
												<button class="btn btn-success"
												        @click="seleccionarBaseDatos(db)">
													<i class="fa fa-chevron-right fa-fw"></i>
												</button>
											</div>
										</template>
										<empty-results v-else text="Sin bases de datos"/>
									</div>
								</template>
							</r-card>
							<button class="btn btn-warning d-block mx-auto"
							        @click="agregarTodas">
								<i class="fal fa-plus"/> Agregar todas
							</button>
						</div>
						<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
							<div class="h6">
								Bases de datos Seleccionadas
								<div class="fw-bolder d-inline">
									({{ formularioConexion.info.bases_datos.length }})
								</div>
							</div>
							<r-card class="my-3 border-1 border-solid border-primary">
								<template #body>
									<div class="overflow-y-auto overflow-x-hidden" style="height: 300px">
										<template v-if="basesDatosSeleccionadas.length > 0">
											<div v-for="db in basesDatosSeleccionadas"
											     class="m-2 d-flex justify-content-between align-items-center"
											     style="content-visibility: auto">
												<button class="btn btn-danger"
												        @click="deseleccionarBaseDatos(db)">
													<i class="fa fa-chevron-left"/>
												</button>
												<div class="mx-2 w-100">{{ db.nombre }}</div>
												<button class="btn btn-info"
												        @click="mostrarInfo(db)">
													<i class="fa fa-info fa-fw"/>
												</button>
											</div>
										</template>
										<empty-results v-else text="Sin bases de datos"/>
									</div>
								</template>
							</r-card>
							<button class="btn btn-warning d-block mx-auto"
							        @click="quitarTodas">
								<i class="fal fa-trash"/> Quitar todas
							</button>
						</div>
					</div>
				</template>
			</r-fieldset>
		</template>
	</r-panel-container>
	<r-off-canvas ref="modalInformacionBaseDatos" position="right" :title="informacionBaseDatos?.nombre">
		<div class="p-3">
			{{ informacionBaseDatos?.descripcion }}
		</div>
		<a class="btn btn-primary btn-sm"
		   target="_blank"
		   :href="informacionBaseDatos?.enlace">
			<i class="fa fa-eye"/>&nbsp;Visitar
		</a>
	</r-off-canvas>
</template>
<script setup lang="ts">
import {useNotify} from "base-app/dist/composable";
import {clearErrors, equals, showErrors} from "base-app/dist/utils";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {IFormulario} from "../../interfaces/IFormulario.ts";
import {IToken} from "../../interfaces/IToken.ts";
import {RCard, RFieldset, RInput, RModal, ROffCanvas, RPanelContainer, RSimpleSelect} from "base-app/dist/components";
import {Tokens} from "../../api/Tokens.ts";
import {IBaseDatos} from "../../interfaces/IBaseDatos.ts";
import {BasesDatos} from "../../api/BasesDatos.ts";
import EmptyResults from "../../components/EmptyResults.vue";

const breadcrumb = computed(() => ([
	{title: 'Tokens', name: 'tokens'},
	{title: tipoFormulario.value + ' token'},
]));

const notify = useNotify();
const router = useRouter();
const route = useRoute();

const letras: { key: string, value: string }[] = [];
for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
	const letra = String.fromCharCode(i);
	letras.push({
		key: `Letra ${letra}`,
		value: letra,
	});
}

const cargando = ref<boolean>(true);
const guardando = ref<boolean>(false);
const basesDatos = ref<IBaseDatos[]>([]);
const letraSeleccionada = ref<string>('A');
const modalInformacionBaseDatos = ref<typeof RModal>();
const informacionBaseDatos = ref<IBaseDatos>();

const ultimosDatosFormulario = ref<IToken>({
	nombre: '',
	token: '',
	bases_datos: [],
});
const formularioConexion = ref<IFormulario<IToken>>({
	info: {
		nombre: '',
		token: '',
		bases_datos: [],
	},
	errores: {
		nombre: [],
		token: [],
		bases_datos: [],
	}
});

onMounted(async () => {
	await cargarInformacionFormulario();
	await cargarToken();
});

const cargarInformacionFormulario = async () => {
	cargando.value = true;
	try {
		const {data} = await BasesDatos.lista();
		basesDatos.value = data;
	} catch {
		notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
		volverAtras();
	} finally {
		cargando.value = false;
	}
};

const cargarToken = async () => {
	if (route.params.id) {
		cargando.value = true;
		try {
			const {data} = await Tokens.porId(route.params.id as string);
			if (data.estado) {
				formularioConexion.value.info.nombre = data.info.nombre;
				formularioConexion.value.info.token = data.info.token;
				formularioConexion.value.info.bases_datos = data.info.bases_datos;
				ultimosDatosFormulario.value = JSON.parse(JSON.stringify(formularioConexion.value.info));
			} else {
				notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
				volverAtras();
			}
		} catch {
			notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
			volverAtras();
		} finally {
			cargando.value = false;
		}
	}
};

const guardar = async () => {
	guardando.value = true;
	clearErrors(formularioConexion.value.errores);
	try {
		let data;
		if (route.params.id) {
			formularioConexion.value.info.id = route.params.id as string;
			const resultado = await Tokens.actualizar(formularioConexion.value.info);
			data = resultado.data;
		} else {
			const resultado = await Tokens.nuevo(formularioConexion.value.info);
			data = resultado.data;
		}
		if (data.estado) {
			notify.success('Datos guardados con exito');
			volverAtras();
		} else {
			showErrors(data.mensajes, formularioConexion.value.errores);
		}
	} catch {
		notify.error('Error al intentar guardar');
		volverAtras();
	} finally {
		guardando.value = false;
	}
}
const volverAtras = () => {
	router.push({
		name: 'tokens',
	});
};

const mostrarInfo = (bd: IBaseDatos) => {
	informacionBaseDatos.value = bd;
	modalInformacionBaseDatos.value?.open();
};
const seleccionarBaseDatos = (bd: IBaseDatos) => {
	formularioConexion.value.info.bases_datos.push(bd.id as string);
};
const deseleccionarBaseDatos = (bd: IBaseDatos) => {
	formularioConexion.value.info.bases_datos = formularioConexion.value.info.bases_datos.filter((id) => id !== bd.id);
};
const agregarTodas = () => {
	basesDatosPorLetra.value.forEach((bd) => {
		seleccionarBaseDatos(bd);
	});
};
const quitarTodas = () => {
	basesDatosSeleccionadas.value.forEach((bd) => {
		deseleccionarBaseDatos(bd);
	});
};

const deshabilitarFormulario = computed(() => {
	return guardando.value === true || equals(ultimosDatosFormulario.value, formularioConexion.value.info);
});

const tipoFormulario = computed(() => {
	return route.params.id ? 'Editar' : 'Nuevo';
});
const basesDatosPorLetra = computed(() => {
	const letra = letraSeleccionada.value.toUpperCase();
	return basesDatosNoSeleccionadas.value.filter((bd) => {
		return bd.nombre.charAt(0).toUpperCase() === letra;
	}).sort((a, b) => a.nombre.localeCompare(b.nombre));
});
const basesDatosNoSeleccionadas = computed(() => {
	return basesDatos.value.filter((item) => {
		return !formularioConexion.value.info.bases_datos.find((id) => id === item.id);
	}).sort((a, b) => a.nombre.localeCompare(b.nombre));
});
const basesDatosSeleccionadas = computed(() => {
	const letra = letraSeleccionada.value.toUpperCase();
	return basesDatos.value.filter((bd) => {
		return formularioConexion.value.info.bases_datos.find((id) => id === bd.id) &&
			bd.nombre.charAt(0).toUpperCase() === letra;
	}).sort((a, b) => a.nombre.localeCompare(b.nombre));
});

</script>