<template>
  <r-overall-loader>
    <template #logo>
      <r-image src="https://cabid.cl/wp-content/uploads/2021/03/cabid-s.png"
               class="h-100 p-1"/>
    </template>
    <template #text>
      <div class="h5 text-center animation-ellipsis">
        Cerrando sesión
      </div>
    </template>
  </r-overall-loader>
</template>
<script setup lang="ts">
import {RImage, ROverallLoader} from "base-app/dist/components";
import {onMounted} from "vue";

onMounted(() => {
  window.location.href = `${import.meta.env.VITE_APP_API_URL}/cerrar-sesion`;
});
</script>