import {RouteRecordRaw} from "vue-router";
import CierreSesion from "../pages/CierreSesion.vue";
import MiPerfil from "../pages/MiPerfil.vue";
import InicioSesion from "../pages/InicioSesion.vue";
import Dashboard from "../pages/dashboard/Dashboard.vue";

export const Admin: RouteRecordRaw[] = [
    {name: 'inicio.sesion', path: '/inicio-sesion', meta: {title: 'Inicio de sesión'},  component: InicioSesion},
    {name: 'cerrar.sesion', path: '/cerrar-sesion', component: CierreSesion},
    {name: 'mi.perfil', path: '/mi-perfil', meta: {title: 'Mi Perfíl'}, component: MiPerfil},
    {name: 'dashboard', path: '/', component: Dashboard},
];