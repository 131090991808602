<template>
	<r-panel-container title="Libros"
	                   icon="fal fa-books"
	                   :breadcrumb="breadcrumb">
		<template #toolbar>
			<router-link class="btn btn-dark"
			             :to="{name: 'libros.nuevo'}">
				<i class="fal fa-plus"/>&nbsp;Nuevo
			</router-link>
		</template>
		<template #body>
			<div class="row">
				<div class="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-sm-12"/>
				<div
					class="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-sm-12 mb-3 d-flex justify-content-end align-items-center">
					<r-check-box v-model="datosPaginacion.filtros.eliminados"
					             :disabled="datosPaginacion.cargando"
					             @change="datosPaginacion.proceso(1)">
						Eliminados
					</r-check-box>
				</div>
				<div class="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-sm-12 mb-3">
					<r-simple-select v-model="datosPaginacion.cantidad"
					                 @change="datosPaginacion.proceso(1)"
					                 style-fill
					                 :can-unselect="false"
					                 :filterable="false"
					                 :disabled="datosPaginacion.cargando"
					                 :options="CANTIDAD_ELEMENTOS_PAGINA"/>
				</div>
				<div class="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-sm-12 mb-3">
					<r-input type="text"
					         style-fill
					         placeholder="Buscar"
					         @keyup.enter.stop="datosPaginacion.proceso(1)"
					         :disabled="datosPaginacion.cargando"
					         v-model="datosPaginacion.filtros.texto_busqueda">
						<template #buttons>
							<button class="btn border-0 h-100"
							        :disabled="datosPaginacion.cargando"
							        @click.prevent.stop="datosPaginacion.proceso(1)">
								<i class="fal fa-search"></i>
							</button>
						</template>
					</r-input>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<table v-if="datosPaginacion.cargando" class="table">
						<tr v-for="_ in 10">
							<td class="w-100">
								<div class="rounded placeholder placeholder-wave w-100">
									<div class="animation-ellipsis">
										Cargando
									</div>
								</div>
							</td>
						</tr>
					</table>
					<template v-else>
						<template v-if="datosPaginacion.resultados.length > 0">
							<div class="table-responsive">
								<table class="table table-sm table-hover table-striped">
									<thead>
									<tr>
										<th class="text-end">#</th>
										<th class="">ISBN</th>
										<th class="">Titulo</th>
										<th class="">Autor/es</th>
										<th/>
									</tr>
									</thead>
									<tbody>
									<tr v-for="(libro, index) in datosPaginacion.resultados"
									    class="show-hover">
										<th class="text-end align-middle px-3 cursor-default user-select-none"
										    v-html="parseInt(`${((datosPaginacion.pagina - 1) * datosPaginacion.cantidad) + index + 1}`)"/>
										<td class="align-middle px-3" v-html="libro.isbn"/>
										<td class="align-middle px-3" v-html="libro.titulo"/>
										<td class="align-middle px-3" v-html="libro.autores.join(', ')"/>
										<td class=" px-3">
											<div class="show-hover-target">
												<div v-if="!datosPaginacion.filtros.eliminados"
												     class="btn-group">
													<router-link
														:to="{name: 'libros.editar', params: { id: libro.id}}"
														class="btn btn-warning text-white btn-sm">
														<i class="fa fa-pen fa-1x"/>
													</router-link>
													<button class="btn btn-danger btn-sm"
													        :disabled="trabajando"
													        @click.prevent="deshabilitar(libro.id as string, index)">
														<i class="fa fa-trash"/>
													</button>
												</div>
												<div v-else class="btn-group">
													<button class="btn btn-success btn-sm"
													        :disabled="trabajando"
													        @click.prevent="reestablecer(libro.id as string, index)">
														<i class="fa fa-sync"></i>
													</button>
													<button class="btn btn-danger btn-sm"
													        :disabled="trabajando"
													        @click.prevent="eliminar(libro.id as string, index)">
														<i class="fa fa-trash"></i>
													</button>
												</div>
											</div>
										</td>
									</tr>
									</tbody>
								</table>
							</div>

							<r-pagination v-model="datosPaginacion.pagina"
							              :disabled="datosPaginacion.cargando"
							              @input="datosPaginacion.proceso"
							              :per-page="datosPaginacion.cantidad"
							              :data="datosPaginacion.resultados"
							              :total="datosPaginacion.total"/>

						</template>
						<empty-results v-else/>
					</template>
				</div>
			</div>
		</template>
	</r-panel-container>
</template>
<script setup lang="ts">
import {RCheckBox, RInput, RPagination, RPanelContainer, RSimpleSelect} from 'base-app/dist/components';
import {onMounted, ref} from "vue";
import {CANTIDAD_ELEMENTOS_PAGINA} from "../../commons/Constants.ts";
import {ILibro} from "../../interfaces/ILibro.ts";
import {IPaginacion} from "../../interfaces/IPaginacion.ts";
import {useDialog, useNotify} from "base-app/dist/composable";
import {Libros} from "../../api/Libros.ts";
import EmptyResults from "../../components/EmptyResults.vue";

const dialog = useDialog();
const notify = useNotify();

const breadcrumb = [
	{title: 'Libros', name: 'libros'},
];

const trabajando = ref<boolean>(false);
const datosPaginacion = ref<IPaginacion<ILibro>>({
	cargando: true,
	cantidad: 15,
	pagina: 0,
	proceso: async (pagina: number) => {
		await cargarDisciplinas(pagina);
	},
	resultados: [],
	total: 0,
	filtros: {
		texto_busqueda: '',
		eliminados: false,
	},
});

onMounted(async () => {
	await cargarDisciplinas(1);
});

const cargarDisciplinas = async (pagina: number) => {
	datosPaginacion.value.pagina = pagina;
	datosPaginacion.value.cargando = true;
	try {
		const {data} = await Libros.paginacion({
			cantidad: datosPaginacion.value.cantidad,
			pagina: datosPaginacion.value.pagina,
			filtros: datosPaginacion.value.filtros,
		});
		datosPaginacion.value.resultados = data.resultados || [];
		datosPaginacion.value.total = data.total;
	} catch {
		notify.error('!Oops!, ocurrio un error, ponte en contacto con el administrador del sistema.');
	} finally {
		datosPaginacion.value.cargando = false;
	}
};

const deshabilitar = (id: string, index: number) => {
	dialog.confirm({
		title: 'Eliminar',
		message: '¿Seguro de eliminar esta libro?',
		okHandler: async (_, closeHandler: () => void) => {
			trabajando.value = true;
			try {
				const {data} = await Libros.deshabilitar(id);
				if (data.estado) {
					notify.success('libro eliminado con exito');
					datosPaginacion.value.resultados.splice(index, 1);
					datosPaginacion.value.total--;
				} else {
					notify.error('Error al intentar eliminar el libro');
				}
			} catch {
				notify.error('Error al intentar eliminar el libro');
			} finally {
				closeHandler();
				trabajando.value = false;
			}
		}
	});
}
const reestablecer = (id: string, index: number) => {
	dialog.confirm({
		title: 'Restaurar',
		message: '¿Seguro de restaurar este temas?',
		okHandler: async (_, closeHandler: () => void) => {
			trabajando.value = true;
			try {
				const {data} = await Libros.reestablecer(id);
				if (data.estado) {
					notify.success('Libro restaurado con exito');
					datosPaginacion.value.resultados.splice(index, 1);
					datosPaginacion.value.total--;
				} else {
					notify.error('Error al intentar restaurar el temas');
				}
			} catch {
				notify.error('Error al intentar restaurar el libro');
			} finally {
				closeHandler();
				trabajando.value = false;
			}
		}
	});
}

const eliminar = (id: string, index: number) => {
	dialog.confirm({
		title: 'Eliminar',
		message: '¿Seguro de eliminar este libro? Esta acción no se puede revertir',
		okHandler: async (_, closeHandler: () => void) => {
			trabajando.value = true;
			try {
				const {data} = await Libros.eliminar(id);
				if (data.estado) {
					notify.success('Libro eliminado con exito');
					datosPaginacion.value.resultados.splice(index, 1);
					datosPaginacion.value.total--;
				} else {
					notify.error('Error al intentar eliminar el libro');
				}
			} catch {
				notify.error('Error al intentar eliminar el libro');
			} finally {
				closeHandler();
				trabajando.value = false;
			}
		}
	});
}

</script>