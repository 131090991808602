const overallLoader = document.createElement('div');
const overallLoaderContainer = document.createElement('div');
const overallLoaderSpinner = document.createElement('div');
const overallLoaderLogo = document.createElement('div');
const overallLoaderImg = document.createElement('img');

overallLoader.classList.add('overall-loader');
overallLoaderContainer.classList.add('overall-loader-container');
overallLoaderSpinner.classList.add('overall-loader-spinner');
overallLoaderLogo.classList.add('overall-loader-logo');

overallLoaderImg.classList.add('h-100', 'p-1');
overallLoaderImg.setAttribute('src', 'https://cabid.cl/wp-content/uploads/2021/03/cabid-s.png');


overallLoaderLogo.appendChild(overallLoaderImg);
overallLoaderContainer.appendChild(overallLoaderSpinner);
overallLoaderContainer.appendChild(overallLoaderLogo);
overallLoader.appendChild(overallLoaderContainer);
document.body.appendChild(overallLoader);

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        document.body.removeChild(overallLoader);
    }, 1000);
});