import {Request} from "../commons/request.ts";
import {IDashboardInfo} from "../interfaces/IDashboardInfo.ts";
import {IResultadoElemento} from "../interfaces/IResultadoElemento.ts";


export const Dashboard = {
    datos: async () => {
        return Request.post<IResultadoElemento<IDashboardInfo>>({
            url: `/dashboard`,
        });
    },
};