import {IResultadoApi} from "../interfaces/IResultadoApi.ts";
import {Request} from "../commons/request.ts";
import {IAutor} from "../interfaces/IAutor.ts";
import {IResultadoElemento} from "../interfaces/IResultadoElemento.ts";
import {IInformacionPaginacion} from "../interfaces/IInformacionPaginacion.ts";
import {IPaginationResponse} from "base-app/dist/interfaces";


export const Autores = {
    lista: async () => {
        return Request.post<IAutor[]>({
            url: `/autores/lista`,
        });
    },
    nuevo: async (autor: IAutor) => {
        return Request.post<IResultadoElemento<IAutor>>({
            url: `/autores/nuevo`,
            data: autor
        });
    },
    actualizar: async (autor: IAutor) => {
        return Request.post<IResultadoElemento<IAutor>>({
            url: `/autores/editar`,
            data: autor
        });
    },
    paginacion: async (informacion: IInformacionPaginacion) => {
        return Request.post<IPaginationResponse<IAutor>>({
            url: `/autores/paginacion`,
            data: informacion
        });
    },
    porId: async (id: string) => {
        return Request.post<IResultadoElemento<IAutor>>({
            url: `/autores/id`,
            data: {id}
        });
    },
    deshabilitar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/autores/deshabilitar`,
            data: {id}
        });
    },
    reestablecer: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/autores/restaurar`,
            data: {id}
        });
    },
    eliminar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/autores/eliminar`,
            data: {id}
        });
    },
};