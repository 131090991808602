import {IResultadoApi} from "../interfaces/IResultadoApi.ts";
import {IInformacionPaginacion} from "../interfaces/IInformacionPaginacion.ts";
import {IPaginationResponse} from "base-app/dist/interfaces";
import {IBaseDatos} from "../interfaces/IBaseDatos.ts";
import {Request} from "../commons/request.ts";
import {IResultadoElemento} from "../interfaces/IResultadoElemento.ts";

export const BasesDatos = {
    lista: async () => {
        return Request.post<IBaseDatos[]>({
            url: `/bases-datos/lista`,
        });
    },
    nuevo: async (baseDatos: IBaseDatos) => {
        return Request.post<IResultadoElemento<IBaseDatos>>({
            url: `/bases-datos/nuevo`,
            data: baseDatos
        });
    },
    actualizar: async (baseDatos: IBaseDatos) => {
        return Request.post<IResultadoElemento<IBaseDatos>>({
            url: `/bases-datos/editar`,
            data: baseDatos
        });
    },
    paginacion: async (informacion: IInformacionPaginacion) => {
        return Request.post<IPaginationResponse<IBaseDatos>>({
            url: `/bases-datos/paginacion`,
            data: informacion
        });
    },
    porId: async (id: string) => {
        return Request.post<IResultadoElemento<IBaseDatos>>({
            url: `/bases-datos/id`,
            data: {id}
        });
    },
    deshabilitar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/bases-datos/deshabilitar`,
            data: {id}
        });
    },
    reestablecer: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/bases-datos/restaurar`,
            data: {id}
        });
    },
    eliminar: async (id: string) => {
        return Request.post<IResultadoApi>({
            url: `/bases-datos/eliminar`,
            data: {id}
        });
    },
};